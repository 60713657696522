<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <article class="requirement-list">
      <slot name="menu-header">
      </slot>
      <details class="match">
        <summary></summary>
        <search-filter v-model="myfilters" />
      </details>
      <div class="requirement-list_container">
        <ul>
        <template v-for="(recruiter, index) in filteredRecruiters">
          <li :key="recruiter.id">
            <div  class="recruiter-item" :class="{selected: recruiterId === recruiter.id}">
              <slot name="edit-button" :id="recruiter.id"></slot>
              <slot name="recruiter" :recruiter="recruiter" :index="index" :id="recruiter.id"></slot>
              <slot name="counter" :id="recruiter.id" :count="getCount(recruiter)"></slot>
            </div>
            <div class="requirement-infos" v-if="recruiter.id === recruiterId">
              <template v-for="requirement in requirements">
                <slot name="menu-search" :requirement="requirement"></slot>
              </template>
              <div v-if="requirements.length < requirementsCount" class="loadmore">
                <button @click="loadRequirements(false)">▼<div>{{ requirements.length + '/' + requirementsCount }}</div></button>
              </div>
            </div>
          </li>
        </template>
        </ul>
      </div>
    </article>
  </section>
</template>
<script>
import Requirement from '/requirement/entity.js'
import props from 'ramda/src/props'
import { Status } from '/interview/status'
import Fuse from 'fuse.js'
import SearchFilter from '/menu/search_filter'

const filters = Object.freeze({
  active: true,
  closed: false,
  archived: false,
  hotOnly: true,
  match: ''
})

export default {
  name: 'hippolyte.conciliator.menu.candidates',
  components: {
    SearchFilter
  },
  props: {
    searchId: String,
    recruiterId: String,
    filters: Object
  },
  data () {
    return {
      id: null,
      requirements: [],
      requirementsCount: 0,
      recruiters: this.$recruiters,
      recruiter: null,
      showRequirements: [false],
      myfilters: { ...this.filters }
    }
  },
  watch: {
    recruiterId: 'selectRecruiter',
    myfilters: {
      handler: function () {
        const link = {
          name: this.$router.currentRoute.name,
          params: Object.assign({}, this.$router.currentRoute.params),
          query: this.prunedQuery
        }
        this.$router.push(link)
        this.refreshRecruiters()
      },
      deep: true
    }
  },
  mounted () {
    this.selectRecruiter(this.recruiterId)
  },
  methods: {
    // @fixme cancel any current loadRequirements
    async selectRecruiter (id) {
      if (id) {
        this.recruiter = this.recruiters.find(r => r.id === id)
        if (!this.recruiter) {
          await this.$loading.recruiters
          this.recruiter = this.recruiters.find(r => r.id === id)
        }
        if (this.recruiter) {
          this.loadRequirements()
        }
      }
    },
    async loadRequirements (flush=true) {
      // @fixme cancel token, loading state, error handling and pagination
      if (flush) {
        this.requirements.splice(0, this.requirements.length)
      }
      const { docs, numFound } = await Requirement.countStatus({
        recruiter: this.recruiter.id,
        status: ['created', 'suggested', 'accepted'],
        active: this.myfilters.active,
        archived: this.myfilters.archived,
        closed: this.myfilters.closed,
        offset: this.requirements.length
      }, this.$socket)
      this.requirements.push(...docs.map(r => {
        return new Requirement(Object.assign(r, { location: r.location.docs[0], trade: r.trade.docs[0] }))
      }))
      this.requirementsCount = numFound
    },
    refreshRecruiters () {
      this.$refreshRecruiters({
        active: this.myfilters.active,
        archived: this.myfilters.archived,
        closed: this.myfilters.closed
      })
    },
    getCount (recruiter) {
      return ['suggested', 'created', 'accepted'].reduce((acc, s) => {
        return acc + recruiter[`conciliation.${s}`].numFound
      }, 0)
    }
  },
  computed : {
    filteredRecruiters () {
      const recruiters = this.recruiters.filter(r => this.myfilters.hotOnly ? this.getCount(r) : true)
      if (this.myfilters.match && this.myfilters.match.trim().length) {
        const fuse = new Fuse(recruiters, {
          ignoreFieldNorm: true,
          ignoreLocation: true,
          threshold: 0.3,
          keys: [
            { name: 'company', weight: 1 },
          ],
          includeScore: true
        })
        return fuse.search(this.myfilters.match).map(i => i.item)
      }
      return recruiters
    },
    prunedQuery () {
      const q = { ...this.$router.currentRoute.query, ...this.myfilters }
      return Object.keys(q).reduce((acc, k) => {
        return Object.assign({}, acc, q[k] !== filters[k] ? { [k]: q[k] } : {})
      }, {})
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
$text = $color-white

.chat-menu {
  padding-top 1em
  position relative
  .close-menu {
    color #73c2bd
  }
  .requirement-list {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1em;
    overflow-y: auto;
    header {
      font-size: 1em;
      margin-left: 1.5rem;
      &:after {
        margin-top: 0.4em;
        content: "";
        display: block;
        width: 20px;
        border: 2px #163ee2 solid;
        border-radius: 4px;
      }
      button {
        float right
        margin 0 1.2em
        padding 0
        background none
        border none
        img {
          width 1.2em
        }
        cursor pointer
      }
    }
    .match {
      ul {
        list-style none
        padding-left 0
        display flex
        flex-direction column
        justify-content center
      }
      input[type="text"] {
        width -webkit-fill-available
        padding-left 0.5em
        height 2em
        background alpha(#d5e4ef, 0.5) right 50% / 13% no-repeat url('/assets/icon/search.svg')
        color #404042
      }
      label {
        padding-left 0.5em
        height 1em
        background none
        color #404042
        font-size 1em
        input {
          height 1em
          width auto
        }
      }
      .active-switch {
        switch($color-yellow, $color-white)
        position absolute
        bottom -20px
        right 4px
      }
      div {
        width -webkit-fill-available
      }
    }
    .requirement-list_container {
      margin-top: 0.5em;
      h4 {
        margin 0
        &:hover {
          cursor pointer
        }
      }

      ul {
        list-style none
        padding-left 0

        li:nth-child(even) {
          background alpha(#b8e0dd, 0.5)
        }

        li.isActive {
          box-sizing border-box
          background #b8e0dd
        }
      }

      .recruiter-item {
        display flex
        align-items center
        padding-left 0.2em
        color #404042
        position relative
        border-right 3px solid transparent
        box-sizing border-box

        button {
          width 1.6em
          padding 0
          svg {
            width 1.6em
          }
        }

        &:hover {
          border-right 3px solid #aaa
          background alpha(#000, 0.2)
        }

        .recruiter-counter {
          font-size 0.7em
          font-weight bold
          color $color-white
          right 0.2em
          width 1.6em
          height 1.6em
          display flex
          align-items center
          justify-content center
          padding 0.2em
          border-radius 50%
          background-color #45ADA7
        }
        &.selected {
          background alpha(#73c2bd, 0.5)
          border-right 3px solid #6b41b9
        }
      }

      .recruiter-name {
        display flex
        align-items center
        text-align left
        padding 0.4em 0
        margin 0.2em 0.5em 0 0.5em
        width 75%

        &-content {
          margin 0 0.2em 0 0
          font-size 0.9em
        }
      }

      .requirement-infos {
        color #404042
      }

      .requirement-item_container {
        position relative
        color $color-menu_text
        color black
        padding-right: 0;
        border-right 3px solid transparent
        &:hover {
          background alpha(#000, 0.3)
          border-right 3px solid #aaa
          cursor pointer
        }
        &.selected {
          background alpha(#73c2bd, 0.5)
          border-right 3px solid #6b41b9

          &:hover {
            background alpha(#000, 0.3)
            }
        }

        .requirement-item_wrapper {
          margin-left 0.5rem
          padding 0.5em 0
          display flex
          flex-direction row
          align-items center

          .requirement-item_infos {
            width 80%

            .requirement-item_trade {
              font-size: 14px;
            }

            .requirement-item_created_at,
            .requirement-item_city {
              font-weight: bold;
              font-size: 12px;
            }
          }

          .requirement-item_active {
            width 20%
          }
        }
      }

      .loadmore {
        button {
          width 100%
          height 1.4em
          border none
          color #404042
          font-size 1.2em
          cursor pointer
          &:hover {
            color #6b41b9
            background #ccc
          }
          position relative
          div {
            position absolute
            right 0
            top 0
            float right
          }
        }

      }
    }
  }
}
</style>
