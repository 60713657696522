<template>
  <section>
    <header>
      <input placeholder="chercher" v-model="q"/>
      <select v-model="service">
        <option value="">Service</option>
        <option v-for="s in services" :key="s" :value="s">{{ s }}</option>
      </select>
      <select v-model="name">
        <option value="">Name</option>
        <option v-for="n in names" :key="n" :value="n">{{ n }}</option>
      </select>
      <select v-model="type">
        <option value="">Type</option>
        <option v-for="t in types" :key="t" :value="t">{{ t }}</option>
      </select>
      <select v-model="from">
        <option value="">From</option>
        <option v-for="f in froms" :key="f" :value="f">{{ f }}</option>
      </select>
      <select v-model="to">
        <option value="">to</option>
        <option v-for="t in tos" :key="t" :value="t">{{ t }}</option>
      </select>
      <label for="error" :class="{error}">Erreurs
        <input type="checkbox" id="error" v-model="error"/>
      </label>
      <select v-model="pending">
        <option :value="null">Traîté</option>
        <option :value="true">Oui</option>
        <option :value="false">Non</option>
      </select>
      <label for="after">après
        <input v-model="after" :max="before" type="datetime-local"/>
      </label>
      <label for="before">avant
        <input v-model="before" :min="after" type="datetime-local"/>
      </label>
      <spinner v-if="cancels.filters" style="transform: scale(0.2, 0.2); display: inline-block; height: 20px;"></spinner>
      <span id="timeline_count" v-else>{{ count }}</span>
    </header>
    <transition-group name="list" tag="div" class="hippo_logs">
      <article v-for="item in items" :key="item.id">
        <header>
          <router-link tag="a" class="title" :to="serviceLink(item)">
            {{ item.error ? '❌' : '✅' }} <b>{{ item.service }} </b>{{ item.name }} {{ item.type }} <span v-if="item.from || to">{{ item.from || '' }}{{ item.from && item.to ? ' → ' : '' }}{{ item.to || '' }}</span></router-link>
          <span class="date">{{ item.createdAt | format('d MMM à H:m:s')}}</span>
        </header>
        <div class="item-content">
          <a class="recruiter" target="blank" :href="recruiterLink(item)" v-if="item.recruiter">{{ item.recruiter.company }}</a>
          <router-link tag="a" class="search" :to="searchLink(item)" v-if="item.search">{{ item.search.title }}</router-link>
          <template v-if="item.candidate && item.candidate.id">
            <router-link tag="a" class="candidate" :to="candidateLink(item)" v-if="item.conciliation.id">{{ item.candidate.firstname }} {{ item.candidate.lastname }}</router-link>
            <div class="candidate" v-else>{{ item.candidate.firstname }} {{ item.candidate.lastname }}</div>
          </template>
          <details v-if="item.conciliation && item.conciliation.statuses">
            <summary>{{ label(item.conciliation.statuses[0]) }} le {{ item.conciliation.statuses[0].date | format('d MMM à H:m:s') }}</summary>
            <ul>
              <template v-for="state in item.conciliation.statuses.slice(1)">
                <li :key="state.id">{{ label(state) }} le {{ state.date | format('d MMM à H:m:s') }}</li>
              </template>
            </ul>
          </details>
          <div class="tracking">
            <button v-if="couldRetry(item)" @click.prevent="retryAts(item)">réessayer</button>
            <div v-if="couldRetry(item)"> &nbsp; |
              <input :id="`toTreat${item.id}`" type="checkbox" v-model="item.pending" @change="togglePending(item)"/>
              <label :for="`toTreat${item.id}`">à traiter</label>
            </div>
          </div>
          <details v-if="item.data" class="item-content_data">
            <summary></summary>
            <pre>{{ item.data }}</pre>
          </details>
          <details v-if="item.error" class="item-content_error">
            <summary></summary>
            <pre>{{ item.error }}</pre>
          </details>
        </div>
      </article>
      <article ref="observable" style="background-color: transparent;" key='observer'>
        <spinner v-if="loading " style="transform: scale(0.2, 0.2);"></spinner>
      </article>
    </transition-group>
  </section>
</template>
<script>
import Spinner from '/layout/spinner'
import { solrEntity } from '/fun'
import Recruiter from '/user/recruiter/recruiter.entity'
import Candidate from '/user/candidate/candidate.entity'
import { normalize } from '/user/candidate/normalize.entity.js'
import Search from '/requirement/entity'
import Conciliation from '/interview/interview.js'
import cancel from '/cancel.mixin.js'
import { Labels } from '/interview/status'
const hasTSId = /id: \'_TS_[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\'/

export default {
  name: 'hippolyte.timeline.list',
  components: { Spinner },
  mixins: [cancel],
  props: {
    query: Object
  },
  data () {
    return Object.assign({
      items: [],
      loading: false,
      offset: 0,
      observer: null,
      q: '',
      service: '',
      name: '',
      type: '',
      from: '',
      to: '',
      error: false,
      services: [],
      names: [],
      types: [],
      froms: [],
      tos: [],
      count: '',
      after: '',
      before: '',
      pending: null
    }, this.query)
  },
  watch: {
    q: 'reset',
    service: 'reset',
    name: 'reset',
    type: 'reset',
    from: 'reset',
    to: 'reset',
    error: 'reset',
    pending: 'reset',
    after: 'reset',
    before: 'reset',
  },
  mounted () {
    this.filters()
    this.$socket.sub(
      `/system/timeline`,
      'TIMELINE',
      this.addItem.bind(this)
    )
    this.$nextTick(() => {
      this.observer = new IntersectionObserver(this.onReachBottom.bind(this), { root: this.$parent.$el})
      this.observer.observe(this.$refs.observable)
    })
  },
  methods: {
    reset () {
      this.items.splice(0, this.items.length)
      this.offset = 0
      this.load()
    },
    async addItem (...items) {
      // @todo item match filter
      this.items.unshift(...items)
    },
    async filters () {
      this.cancel('filters')
      try {
        const filters = await this.$socket.service(
          'timeline/FILTER',
          {},
          { cancel: this.token('filters') }
        )
        this.cancel('filters', null)
        this.services.push(...filters.service.buckets.map(s => s.val).sort())
        this.types.push(...filters.type.buckets.map(s => s.val).sort())
        this.names.push(...filters.name.buckets.map(s => s.val).sort())
        this.froms.push(...filters.from.buckets.map(s => s.val).sort())
        this.tos.push(...filters.to.buckets.map(s => s.val).sort())
      } catch (err) {
        console.log(err)
        try {
          this.handleCancel(err)
        } catch {
          this.busy = false
        }
      }
    },
    queryRoute () {
      return {
        name: 'timeline',
        query: {
          q: this.q.length > 3 ? this.q : undefined,
          service: this.service.length ? this.service : undefined,
          name: this.name.length ? this.name : undefined,
          type: this.type.length ? this.type : undefined,
          from: this.from.length ? this.from : undefined,
          to: this.to.length ? this.to : undefined,
          error: this.error ? 'true' : undefined,
          pending: typeof this.pending === 'boolean' ? String(this.pending) : undefined,
          before: this.before.length ? new Date(this.before).toISOString() : undefined,
          after: this.after.length ? new Date(this.after).toISOString() : undefined
        }
      }
    },
    async load () {
      this.cancel('items')
      this.busy = true
      this.$router.push(this.queryRoute())
      try {
        this.loading = this.$socket.service(
          'timeline/LIST',
          {
            q: this.q.length > 3 ? this.q : null,
            service: this.service.length ? this.service : null,
            name: this.name.length ? this.name : null,
            type: this.type.length ? this.type : null,
            from: this.from.length ? this.from : null,
            to: this.to.length ? this.to : null,
            offset: this.items.length,
            error: this.error ? '*' : null,
            inspected: this.inspected ? '*' : null,
            pending: typeof this.pending === 'boolean' ? String(this.pending) : undefined,
            before: this.before.length ? new Date(this.before).toISOString() : null,
            after: this.after.length ? new Date(this.after).toISOString() : null,
            limit: 10
          },
          { cancel: this.token('items') }
        )
        this.items.push(...await this.loading.then(({ docs, numFound }) => {
          this.count = numFound
          return docs.map(doc => Object.assign(
            doc,
            solrEntity(doc, {
              recruiter: d => new Recruiter(d),
              conciliation: d => new Conciliation(d),
              candidate: d => new Candidate(normalize(d)),
              search: d => new Search(d)
            }),
            {expand: false }
          ))
        }))
        this.busy = false
        this.cancel('items', null)
      } catch (err) {
        console.log(err)
        try {
          this.handleCancel(err)
        } catch {
          this.busy = false
        }
      }
      this.loading = null
      this.isLoading = false
    },
    togglePending (item) {
      try {
        this.$socket.service('timeline/UPDATE', { pending: item.pending, id: item.id })
      } catch (err) {
        console.error(err)
      }
    },
    async onReachBottom (entries, observer) {
      const entrie = entries.find(e => e.target === this.$refs.observable)
      if (entrie?.isIntersecting) {
        if (await this.load()) {
          this.resetObserver()
        }
      }
    },
    resetObserver () {
      if (this.$refs.observable && this.$refs.observable instanceof Element) {
        this.observer.unobserve(this.$refs.observable)
        this.observer.observe(this.$refs.observable)
      }
    },
    couldRetry (item) {
      return (item.error && item.service === 'ats' && ['kinougarde','gestmax', 'talentsoft', 'talentlink', 'smartrecruiters', 'talentview', 'teamtailor', 'jobaffinity', 'careerbuilder', 'workday', 'digitalrecruiters', 'jobplus', 'eolia', 'talentsoft-frontoffice', 'talentplug', 'beetween', 'altays', 'handcraft', 'icims', 'atome', 'samsic', 'cornerstone', 'werecruit2', 'greenhouse', 'manpower'].includes(item.name)) || (item.service === 'ats' && item.name === 'talentsoft' && item.data && !hasTSId.test(item.data))
    },
    async retryAts (item) {
      try {
        const data = await this.$socket.service(`${item.name}.jobs/SEND`, {
          search: item.search.id,
          candidate: item.candidate.id,
          recruiter: item.recruiter.id,
          conciliation: item.conciliation.id
        })
        this.$toast(`${item.candidate.firstname} ${item.candidate.lastname} transféré`, { theme: 'success' })
      } catch (err) {
        console.error(err)
        this.$toast(`${item.candidate.firstname} ${item.candidate.lastname} n'a pas pu être transféré`, { theme: 'error' })
      }
    },
    serviceLink (item) {
      const opts = {}
      switch (item.service) {
        case 'ats':
          return {
            name: 'ats',
            params: {
              recruiter: item.recruiter.id,
              ats: item.name
            },
            query: {
              search: item.search.id
            }
          }
          break
        default:
          return this.queryRoute()
      }
      return opts
    },
    searchLink (item) {
      return {
        name: 'candidate_list',
        params: {
          search: item.search.id
        }
      }
    },
    recruiterLink (recruiter) {
      return board('recruiter', recruiter.id, 'profile')
    },
    candidateLink (item) {
      return {
        name: 'candidates_profile',
        params: {
          search: item.search.id,
          interview: item.conciliation.id
        },
        query: {
          states: item.conciliation.statuses[0].status
        }
      }
    },
    label (state) {
      return Labels[state.status].singular
    }
  },
  destroyed () {
    this.$socket.unsub('/system/timeline', 'TIMELINE')
    this.observer.disconnect()
  }
}

function board (...path) {
  return [
    document.location.protocol, '',
    document.location.host.replace('conciliator', 'conciliateur'),
    ...path
  ].join('/')
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
section
  header
    display flex
    align-items center
    input, select, label[for="error"]
      border 1px #cccc solid
      border-radius 4px
      margin .5em
      padding .5em
      color #bbb
      font-size .9em
      cursor pointer
    label[for="error"]
      color #000
      &::after
        content '❌'
        opacity 0
      &.error::after
        opacity 1
      input
        display none
    #timeline_count
      font-size 1.8em
    select
      background-color white
      color black
  margin 1em
  height calc(100% - 2em)
  overflow-y scroll
  .hippo_logs
    article
      margin 1em
      color $color-astronaut
      border-radius 0.2em
      border 1px solid $color-astronaut
      background-color $color-blue_lighter
      width 50%
      details
        ul
          padding 0 .5em 0
          list-style-type none
          margin 0
        summary::marker
          content "+"
          color #ADCA48
          font-size 1.5em
          font-weight bold
        &[open] summary::marker
          content "-"
      header
        display flex
        flex-direction column
        margin 0.4em 1em
        .date
          font-size 0.8em
      .item-content
        margin 1em
        line-height 1.5em
        display flex
        flex-direction column
        overflow hidden
        .tracking
          display flex
          justify-content flex-end
          label
            font-size 14px
      a
        text-decoration none
        color $color-astronaut
        &::visited
          color $color-astronaut
  .item-content_data, .item-content_error
    cursor pointer
    border 1px solid white
    border-radius 4px
    summary
      text-align end
      width 100%
    pre
      margin 0
      background white
      white-space pre-wrap
  .list-enter-active, .list-leave-active
    transition all 1s
    max-height 160px
  .list-enter, .list-leave-to
    opacity 0
    max-height 0px
</style>
