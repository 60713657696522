import Socket from 'minou/src/socket'
import Auth from 'minou/src/auth'
import { CandidateProfiles } from '/profile/profiles'
import Account from '/user/account/entity'
import Recruiter from '/user/recruiter/recruiter.entity'
import Location from '/location/entity'
import Chat from 'minou/src/chat'
import Interview from '/interview/interview.js'
import Requirement from '/requirement/entity.js'
import Candidate from '/user/candidate/candidate.entity.js'
import Favico from 'favico.js'
import login from '/session/login.js'
import { Status } from '/interview/status'
import Conciliator from '/user/conciliator/role'
import order from '/interview/order'
import debug from 'debug'
const log = debug('context')

function mustAuthRoute (hash) {
  let splited = hash.split('/')
  return splited.length > 1
    ? !['login', 'signin'].includes(splited[1])
    : true
}
const status = [
  Status.suggested,
  Status.sent,
  Status.opened,
  Status.started,
  Status.archived,
  Status.rejected,
  Status.discarded
]
const statusKeep = [Status.suggested]

export default class Context {
  constructor (config) {
    this.config = config
    this.loading = {}
    this.socket = new Socket(null, undefined, this.config)
    this.auth = new Auth(this.socket)
    this.listenSocket()
    this.socket.auth = this.auth
    this.profiles = new CandidateProfiles(this.socket)
    this.profiles.socket = this.socket
    this.loaded = false
    this.recruiter = new Recruiter({ location: new Location() }, this.socket)
    this.account = new Account({}, this.socket)
    this.chat = new Chat(this.socket, this.config)
    this.interviews = []
    this.recruiters = []
    this.search = null
    this.favicons = document ? Array.from(document.querySelectorAll('head link[rel="shortcut icon"]')).map(el => new Favico({
      animation: ('fade'),
      element: el
    })) : []
    this.show = {
      toolbar: true,
      menu: true,
      interview: false,
      profile: false
    }
  }
  listenSocket () {
    this.connected = { state: null  }
    this.socket.on('connect', () => {
      log('connected')
      this.connected.state = true
    })
    this.socket.on('disconnect', () => {
      log('disconnected')
      this.connected.state = false
    })
  }
  async load () {
    await this.socket.getSession()
    await this.ensureLogin()
    if (this.auth.user) {
      this.socket.getSocket().on('disconnect', () => this.favicons.forEach( f => f.badge('🔌')))
      this.socket.getSocket().on('connect', () => this.favicons.forEach( f => f.reset()))
      await this.loadAccount()
      await this.loadChat()
      this.loaded = true
    }
  }
  async loadAccount () {
    log('loading account')
    const account = await Account.getByChatUser(this.auth.user.id, this.socket)
    if (!account) {
      let parsed = new URL(document.location.href)
      parsed.pathname = '/candidate.html'
      window.location.replace(parsed.toString())
    }
    let data = account.marshall()
    this.account.setData(data)
    this.recruiter.setData(data.recruiter)
  }
  async loadRecruiters (opts = {active: true, archived: false, active: true}) {
    const key = JSON.stringify(opts)
    if (this.lastLoadingRecruiters !== key) {
      // @todo cancel
      this.lastLoadingRecruiters = key
      this.recruiters.splice(0, this.recruiters.length)
      const status = ['suggested', 'created', 'accepted']

      this.loading.recruiters = new Promise(async resolve => {
        const t = new Date()
        this.socket.sub('/entity/Conciliation', 'WORKFLOW', this.onConciliationStatus.bind(this))
        let offset = 0
        const limit = 50
        let next = true
        while (next) { // @todo use solr cursor
          const response = await Recruiter.countStatus(Object.assign({ status, offset, limit }, opts), this.socket)
          this.recruiters.push(...response.docs.map(r => new Recruiter(r)))
          offset += limit
          next = response.numFound > offset
        }
        resolve(this.recruiters)
      })
    }
    return this.loading.recruiters
  }

  async setSearch (search) {
    if (this.search && search === this.search) { return }
    if (!search) { return }
    this.search = search
  }
  async onConciliationStatus (data) {
    log('conciliations status update')

    const id = data.room.split('/').pop(id)
    const from = data.body.from[0]
    /* const from = data.from[0] */
    const state = data.body.state[0]
    /* const state = data.state[0] */
    let search
    let conciliation
    let searchId = ''
    if (from && status.includes(from)) {
      search = this.requirements.find(s => s.conciliations.find(c => c.id === id))
      if (search) {
        conciliation = search.conciliations.find(c => c.id === id)
      }
    }

    // update created and suggested counter for each search
    if (from) {
      const conciliation = new Interview({ id }, this.socket)
      await conciliation.load()
      searchId = conciliation.search.id
      const requirementToUpdate = this.requirements.find(r => r.id === searchId)
      if ('created' === from) {
        switch (state) {
          case 'suggested':
            requirementToUpdate['conciliation.created'].numFound = Math.max(0, requirementToUpdate['conciliation.created'].numFound - 1)
            requirementToUpdate['conciliation.suggested'].numFound++
            break
          case 'sent':
            requirementToUpdate['conciliation.created'].numFound = Math.max(0, requirementToUpdate['conciliation.created'].numFound - 1)
        }
      }
      if ('suggested' === from) {
        switch (state) {
          case 'sent':
            requirementToUpdate['conciliation.suggested'].numFound = Math.max(0, requirementToUpdate['conciliation.suggested'].numFound - 1)
            break
          case 'discarded':
            requirementToUpdate['conciliation.suggested'].numFound = Math.max(0, requirementToUpdate['conciliation.suggested'].numFound - 1)
        }
      }
      if ('discarded' === from && 'suggested' === state) {
        requirementToUpdate['conciliation.suggested'].numFound++
      }
    }
    if (status.includes(state)) { // we should add or update
      if (!conciliation) { // create conciliation
        let candidate = new Candidate({}, this.socket)
        conciliation = new Interview({ id, candidate }, this.socket)
        if (statusKeep.includes(state) || this.search) {
          await conciliation.load()
          search = this.requirements.find(s => s.id === conciliation.search.id)
          if (!search && statusKeep.includes(state)) { // create search
            search = conciliation.search
            this.requirements.push(search)
            search.load()
          }
          search.conciliations.push(conciliation)
          conciliation.candidate.load()
        }
      } else if (conciliation.status !== state) { // update
        conciliation.load()
      }
    } else if (search && conciliation) { // remove
      let at = search.conciliations.indexOf(conciliation)
      search.conciliations.splice(at, 1)
      conciliation.destroy()
      if (search.conciliations.length < 1) {
        at = this.requirements.indexOf(search)
        this.requirements.splice(at, 1)
        search.destroy()
      }
    }
  }
  async loadChat () {
    log('loading chat')
    await this.chat.loadRooms()
    let ids = this.chat.rooms.map(r => r.id.split('/').pop())
    this.profiles.load(...ids)
    this.chat
      .on('room:add', this.onRoomAdd, this)
      .on('room:remove', this.onRoomRemove, this)
    this.socket.on('reconnect', () => {
      this.chat.rooms.map(room => {
        room.history()
      })
    })
  }
  async onRoomAdd (data) {
    this.profiles.load(data.id.split('/').pop())
    let room = this.chat.rooms.find(({ id }) => id === data.id)
    if (!room) {
      room = this.chat.create(data, false)
      room.on('unread:message', this.warnUnread, this)
    }
    this.chat.emit('room:update', room)
  }
  async onRoomRemove (room) {
    if (!room) { return }
    this.chat.close(room , false)
    room.off('unread:message', this.warnUnread, this)
    this.chat.emit('room:update', room)
  }
  async ensureLogin () {
    let parsed = new URL(document.location.href)
    if (parsed.searchParams.has('token')) {
      try {
        this.auth.logout()
        await login(this.socket, parsed.searchParams.get('token'))
      } catch {
        parsed.hash = '/login'
      }
      parsed.searchParams.delete('token')
      window.location.replace(parsed.toString())
    }
    if (mustAuthRoute(parsed.hash)) {
      if (!(this.auth.user && this.auth.user.id)) {
        parsed.hash = '/login'
        window.location.replace(parsed.toString())
      }
      if (!this.auth.user.roles) {
        await this.auth.getToken()
      }
      if (!this.auth.user.roles.includes(Conciliator)) {
        parsed.pathname = '/recruiter.html'
        window.location.replace(parsed.toString())
      }
    }
  }
  inject (to) {
    Object.assign(
      to,
      {
        $socket: this.socket,
        $auth: this.auth,
        $config: this.config,
        $chat: this.chat,
        $show: this.show,
        $profiles: this.profiles,
        $interviews: this.interviews,
        $account: this.account,
        $requirements: this.requirements,
        $recruiters: this.recruiters,
        $recruiter: this.recruiter,
        $connected: this.connected,
        $loading: this.loading,
        $refreshRecruiters: (opts) => this.loadRecruiters(opts),
        $isAdmin: () => {
          return this.auth?.user?.roles?.includes(Conciliator)
        }
      }
    )
  }
  selectInterview (interview) {
    if (interview) {
      interview.isSent() && interview.open()
      let room = this.chat.roomId(interview.roomId(this.account))
      if (room) {
        this.chat.join(room)
      } else if (this.chat.current) {
        this.chat.current.quit()
        this.chat.current = null
      }
    } else if (this.chat.current) {
      this.$chat.current.quit()
      this.$chat.current = null
    }
    return interview
  }
  warn (txt) {
    if (document.visibilityState !== 'visible') {
      if (!this.resetWarn) {
        this.resetWarn = () => {
          this.favicons.forEach(f => f.reset())
          document.removeEventListener('visibilitychange', this.resetWarn)
          if (this.chat.current && this.chat.current.unread) {
            const r = this.chat.current
            r.lastSeen(r.messages[r.messages.length - 1])
            r.unread = 0
          }
          this.resetWarn = null
        }
        document.addEventListener('visibilitychange', this.resetWarn)
      }
      this.favicons.forEach(f => f.badge(txt))
    }
  }
  warnUnread () {
    this.warn(this.chat.rooms.reduce((sum, { unread }) => sum + unread, 0))
  }
}
